@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --nft-gradient-color: linear-gradient(
    79deg,
    rgba(99, 69, 237, 1) 0%,
    rgba(224, 57, 253, 1) 100%
  );
  --gradient-btn-hover: linear-gradient(
    90deg,
    rgba(224, 57, 253, 1) 0%,
    rgba(99, 69, 237, 1) 100%
  );
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0B0E13;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  width: 100%;
}
.header {
  background: linear-gradient(rgba(29, 29, 27, 0.78), rgba(29, 29, 27, 0.78));
}
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 0px;
}

body::-webkit-scrollbar-track {
  background: var(--black-color2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 25%,
    transparent 20%,
    transparent 50%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.3) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 0px;
}

.over {
  scrollbar-width: thin; 
  scrollbar-color: var(--theme-color) var(--black-color2); 
}


.over::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.over::-webkit-scrollbar-track {
  background: var(--black-color2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.over::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 25%,
    transparent 20%,
    transparent 50%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.3) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 0px;
}

.over-black {
  scrollbar-width: thin; 
  scrollbar-color: var(--theme-color) var(--black-color2); 
}


.over-black::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.over-black::-webkit-scrollbar-track {
  background: var(--black-color2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.over-black::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 25%,
    transparent 20%,
    transparent 50%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.3) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 0px;
}



.btn {
  user-select: none;
  -moz-user-select: none;
  background: var(--nft-gradient-color);
  border: medium none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn::before {
  content: "";
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 4px;
  top: 4px;
  border: 1.5px dashed #fff;
  border-radius: 30px;
}
.btn::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--gradient-btn-hover);
  z-index: -1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
}
.btn:hover {
  color: #fff;
}
.btn:hover::after {
  opacity: 1;
  transition: all 0.5s ease;
}
.txt:hover {
  color: #45f882;
}

.bx {
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  body {
    .btn {
      user-select: none;
      -moz-user-select: none;
      background: var(--nft-gradient-color);
      border: medium none;
      border-radius: 25px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1;
      margin-bottom: 0;
      padding: 12px;
      text-align: center;
      text-transform: uppercase;
      touch-action: manipulation;
      transition: all 0.3s ease 0s;
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
  }
}

.header-button {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.header-button .th-btn {
  margin-left: 10px;
}

.header-button .icon-btn .badge {
  font-size: 12px;
  top: 0;
  right: 0;
}

.header-button .icon-btn:hover .badge {
  background-color: var(--title-color);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.sidebar-down {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

