*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --theme-color: #45f882;
  --theme-color2: #ffbe18;
  --theme-color3: #6240cf;
  --title-color: #0b0e13;
  --body-color: #969eb2;
  --smoke-color: #f5f7fa;
  --smoke-color2: #f5f8fd;
  --black-color: #000000;
  --black-color2: #1a1d26;
  --black-color3: #0f1c23;
  --gray-color: #d8dde1;
  --white-color: #ffffff;
  --light-color: #bdbdbd;
  --yellow-color: #ffb539;
  --success-color: #28a745;
  --error-color: #45f882;
  --th-border-color: #6c6c6c;
  --th-border-color2: #e6e6e6;
  --title-font: "Rajdhani", sans-serif;
  --body-font: "Poppins", sans-serif;
  --war-font: "warPriest";
  --war-font2: "warPriest3d";
  --goldman-font: "Goldman", sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1225px;
  --container-gutters: 24px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
  --th-body-background: #0b0e13;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
  font-family: sans-serif;
}

button,
input {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: #1d1d1b;
  color: #4c768d;
  padding-top: 60px;
}

/* navbar */
.nav-bar {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4c768d;
  color: #242443;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.sign-in {
  border: none;
  background-color: transparent;
}
.sign-in > img {
  height: 30px;
  width: auto;
}
.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  color: #88dded;
  border: 1px solid #1c2c4c;
  background-color: #1c2c4c;
  font-weight: 600;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #7cc5d9;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

/* chat component */
.messages-wrapper {
  padding: 30px;
  margin-bottom: 60px;
}
.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 15px;
  background-color: #7cc5d9;
  color: #1c2c4c;
  width: max-content;
  max-width: calc(100% - 50px);
  box-shadow: -2px 2px 1px 1px #4c768d;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  background-color: #fff;
  box-shadow: -2px 2px 1px 1px #88dded;
}
.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1c2c4c;
}
.user-message {
  word-break: break-all;
}
.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-color: #4c768d;
  display: flex;
}
.send-message > input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: white;
  color: #1c2c4c;
  font-size: 1rem;
}
.send-message > input:placeholder {
  color: #ddd;
}
.send-message > :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #7cc5d9;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  color: #242443;
  border: 1px solid #7cc5d9;
  background-color: #7cc5d9;
  font-weight: 600;
}

.theme-red {
  --theme-color: #45f882;
}

.theme-red .th-btn {
  color: var(--white-color);
}

.theme-red .th-btn.style-border3:hover .btn-border {
  color: var(--white-color);
}

.theme-red .th-btn.style-border2 .btn-border {
  color: var(--white-color);
}

.th-btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--title-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 14px 30px;
  min-width: 200px;
  border-radius: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* Extra small devices */
}

.th-btn:before,
.th-btn:after {
  content: "";
  position: absolute;
  background-color: var(--title-color);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  top: 5px;
  left: 5px;
  width: 16px;
  height: calc(100% - 10px);
  border-radius: 0;
  transition: -webkit-clip-path 0.2s ease;
  -webkit-transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
  -webkit-clip-path: polygon(
    85% 0,
    100% 0,
    15% 50%,
    100% 100%,
    85% 100%,
    0% 50%
  );
  clip-path: polygon(85% 0, 100% 0, 15% 50%, 100% 100%, 85% 100%, 0% 50%);
}

.th-btn:after {
  right: 5px;
  left: auto;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.th-btnn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--title-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 12px 10px;
  min-width: 150px;
  border-radius: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* Extra small devices */
}
.th-btnn:before,
.th-btnn:after {
  content: "";
  position: absolute;
  background-color: var(--title-color);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  top: 5px;
  left: 5px;
  width: 16px;
  height: calc(100% - 10px);
  border-radius: 0;
  transition: -webkit-clip-path 0.2s ease;
  -webkit-transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
  -webkit-clip-path: polygon(
    85% 0,
    100% 0,
    15% 50%,
    100% 100%,
    85% 100%,
    0% 50%
  );
  clip-path: polygon(85% 0, 100% 0, 15% 50%, 100% 100%, 85% 100%, 0% 50%);
}


.th-btnnn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--title-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 12px 10px;
  min-width: 180px;
  border-radius: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* Extra small devices */
}
.th-btnnn:before,
.th-btnnn:after {
  content: "";
  position: absolute;
  background-color: var(--title-color);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  top: 5px;
  left: 5px;
  width: 16px;
  height: calc(100% - 10px);
  border-radius: 0;
  transition: -webkit-clip-path 0.2s ease;
  -webkit-transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
  -webkit-clip-path: polygon(
    85% 0,
    100% 0,
    15% 50%,
    100% 100%,
    85% 100%,
    0% 50%
  );
  clip-path: polygon(85% 0, 100% 0, 15% 50%, 100% 100%, 85% 100%, 0% 50%);
}



.th-btnn:after {
  right: 5px;
  left: auto;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.th-btnnn:after {
  right: 5px;
  left: auto;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.th-btnnn:before,
.th-btnnn:after {
  content: "";
  position: absolute;
  background-color: var(--title-color);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  top: 5px;
  left: 5px;
  width: 14px;
  height: calc(100% - 10px);
  border-radius: 0;
  transition: -webkit-clip-path 0.2s ease;
  -webkit-transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
  -webkit-clip-path: polygon(
    85% 0,
    100% 0,
    15% 50%,
    100% 100%,
    85% 100%,
    0% 50%
  );
  clip-path: polygon(85% 0, 100% 0, 15% 50%, 100% 100%, 85% 100%, 0% 50%);
}

.th-btnnn:after {
  right: 5px;
  left: auto;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.th-btn i {
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

.th-btn:hover,
.th-btn.active {
  background: var(--white-color);
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
  color: var(--title-color);
}

.th-btn:hover:before,
.th-btn:hover:after,
.th-btn.active:before,
.th-btn.active:after {
  -webkit-clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
}

.th-btnn:hover,
.th-btnn.active {
  background: var(--white-color);
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
  color: var(--title-color);
}

.th-btnn:hover:before,
.th-btnn:hover:after,
.th-btnn.active:before,
.th-btnn.active:after {
  -webkit-clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
}

.th-btnnn:hover,
.th-btnnn.active {
  background: var(--white-color);
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
  color: var(--title-color);
}

.th-btnnn:hover:before,
.th-btnnn:hover:after,
.th-btnnn.active:before,
.th-btnnn.active:after {
  -webkit-clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    2px 60%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
}


.active-tab {
  background-color: black;
  color: white;
}


.th-btnnn:active {
  background-color: var(--black-color);
}

.th-btn.style2 {
  background-color: var(--theme-color2);
}

.th-btn.style2:hover {
  background: var(--theme-color);
}

.th-btn.style3 {
  background: transparent;
}

.th-btn.style3:hover {
  color: var(--title-color);
}

.th-btn.style3:hover:before,
.th-btn.style3:hover:after {
  background-color: var(--white-color);
}

.th-btn.style4 {
  background-color: var(--theme-color2);
  color: var(--white-color);
}

.th-btn.style4:hover {
  color: var(--white-color);
}

.th-btn.style4:hover:before,
.th-btn.style4:hover:after {
  background-color: var(--title-color);
}

.th-btn.style5 {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.th-btn.style5:hover {
  color: var(--theme-color);
}

.th-btn.style5:hover:before,
.th-btn.style5:hover:after {
  background-color: var(--white-color);
}

.th-btn.style6 {
  background-color: var(--title-color);
}

.th-btn.style6:hover {
  color: var(--theme-color);
}

.th-btn.style6:hover:before,
.th-btn.style6:hover:after {
  background-color: var(--white-color);
}

.th-btn.style7 {
  background-color: var(--white-color);
  color: var(--title-color);
}

.th-btn.style-border {
  display: inline-block;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  background: var(--theme-color);
  padding: 0;
}

.th-btn.style-border .btn-border {
  display: inline-block;
  background-color: var(--title-color);
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  border: 0;
  width: -webkit-fill-available;
  color: var(--white-color);
  border-radius: 0;
  margin: 2px;
  padding: 19px 30px;
}

.th-btn.style-border:after,
.th-btn.style-border:before {
  -webkit-clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  top: 6px;
  left: 6px;
  width: 26px;
  height: calc(100% - 12px);
  background: var(--theme-color);
  z-index: 1;
}

.th-btn.style-border:after {
  right: 6px;
  left: auto;
}

.th-btn.style-border:hover {
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
}

.th-btn.style-border:hover .btn-border {
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
}

.th-btn.style-border:hover:after,
.th-btn.style-border:hover:before {
  -webkit-clip-path: polygon(
    2px 50%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    2px 50%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
}

.th-btn.style-border2 {
  display: inline-block;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  background: var(--white-color);
  padding: 0;
}

.th-btn.style-border2 .btn-border {
  display: inline-block;
  background-color: var(--title-color);
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  border: 0;
  width: -webkit-fill-available;
  color: var(--theme-color);
  border-radius: 0;
  margin: 2px;
  padding: 19px 30px;
}

.th-btn.style-border2:after,
.th-btn.style-border2:before {
  -webkit-clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  top: 6px;
  left: 6px;
  width: 26px;
  height: calc(100% - 12px);
  background: var(--white-color);
  z-index: 1;
}

.th-btn.style-border2:after {
  right: 6px;
  left: auto;
}

.th-btn.style-border2:hover {
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
}

.th-btn.style-border2:hover .btn-border {
  -webkit-clip-path: polygon(
    0px 0%,
    100% 0%,
    100% 50%,
    100% 100%,
    0 100%,
    0% 50%
  );
  clip-path: polygon(0px 0%, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);
}

.th-btn.style-border2:hover:after,
.th-btn.style-border2:hover:before {
  -webkit-clip-path: polygon(
    2px 50%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
  clip-path: polygon(
    2px 50%,
    2px calc(100% - 2px),
    100% calc(100% - 0px),
    100% 100%,
    0 100%,
    0 100%
  );
}

.th-btn.style-border3 {
  display: inline-block;
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  background: var(--theme-color);
  padding: 0;
}

.th-btn.style-border3 .btn-border {
  display: inline-block;
  background-color: var(--title-color);
  -webkit-clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  clip-path: polygon(
    15px 0%,
    calc(100% - 15px) 0%,
    100% 50%,
    calc(100% - 15px) 100%,
    15px 100%,
    0% 50%
  );
  border: 0;
  width: -webkit-fill-available;
  color: var(--white-color);
  border-radius: 0;
  margin: 1px;
  padding: 19px 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.th-btn.style-border3 .btn-border i {
  -webkit-transition: none;
  transition: none;
}

.th-btn.style-border3:after,
.th-btn.style-border3:before {
  -webkit-clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  clip-path: polygon(45% 0, 50% 0, 5% 50%, 50% 100%, 45% 100%, 0% 50%);
  top: 6px;
  left: 8px;
  width: 26px;
  height: calc(100% - 12px);
  background: var(--theme-color);
  z-index: 1;
}

.th-btn.style-border3:after {
  right: 8px;
  left: auto;
}

.th-btn.style-border3:hover {
  background: var(--theme-color);
}

.th-btn.style-border3:hover .btn-border {
  background: var(--theme-color);
  color: var(--title-color);
}

.th-btn.style-border3:hover:after,
.th-btn.style-border3:hover:before {
  background: var(--title-color);
}

.th-btn.btn-fw {
  width: 100%;
}

.th-btn.btn-fw:before,
.th-btn.btn-fw:after {
  display: none;
}

.th-btn.btn-fw:hover {
  background: var(--white-color);
  color: var(--title-color);
}

.th-btn.btn-sm {
  padding: 16px 25px;
  min-width: 152px;
}

@media (max-width: 575px) {
  .th-btn {
    padding: 17px 30px;
    min-width: 180px;
  }
  .th-btn .btn-border svg {
    height: 100%;
    width: 100%;
  }
  .th-btn.style-border:before,
  .th-btn.style-border:after {
    height: calc(100% - 16px);
    top: 8px;
  }
}
